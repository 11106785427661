/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Header from './header';
import '../../static/styles/main.scss';
import logo from '../../static/assets/logo-512.png';

const Layout = ({ children }) => (
  <>
    <Header siteTitle="EasyBusy" />
    <main>{children}</main>
    <footer>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-5'}>
            <div className={'widget__item'}>
              <div className={'footer-logo'}>
                <Link to="/" title={'EasyBusy'}>
                  <img alt={'Logo'} src={logo} />
                </Link>
              </div>

              <div className={'about'}>
                <p>
                  A productivity app that will help you keep all your tasks and ideas
                  in one place, complete them on time and never forget anything, all
                  that will fun and no stress!
                </p>
              </div>
            </div>
          </div>

          <div className={'col-2'}>
            <div className={'widget__item'}>
              <ul className={'links'}>
                <h4>EasyBusy</h4>
                <ul>
                  {/*<li>*/}
                  {/*  <Link to="/integrations/slack" title={'Integrations'}>*/}
                  {/*    Integrations*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li>
                    <Link to="/about" title={'About Us'}>
                      About
                    </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <a href={'https://histaff.io/blog'}>Blog</a>*/}
                  {/*</li>*/}
                  <li>
                    <a
                      href={'https://easybusy.convas.io/roadmap'}
                      target={'_blank'}
                      title={'Look at our planned new features'}
                      rel="noreferrer"
                    >
                      Roadmap
                    </a>
                  </li>
                  <li>
                    <a
                      className={'links__special'}
                      href={'https://easybusy.convas.io/'}
                      target={'_blank'}
                      title={'We look forward to receiving your great feedback'}
                      rel="noreferrer"
                    >
                      Feedback
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>

          <div className={'col-2'}>
            <div className={'widget__item'}>
              <div className={'links'}>
                <h4>Support</h4>
                <ul>
                  {/*<li>*/}
                  {/*  <Link to="/contact" title={'Contact Us'}>*/}
                  {/*    Contact*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li>
                    <Link to="/privacy-policy.html" title={'Privacy Policy'}>
                      Privacy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions.html" title={'Terms Of Use'}>
                      Terms Of Use
                    </Link>
                  </li>
                  <li>
                    <Link to="/data-policy.html" title={'Data Policy'}>
                      Data Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={'col-3'}>
            <div className={'widget__item'}>
              <div className={'social'}>
                <a
                  href="https://twitter.com/easybusydo"
                  target={'_blank'}
                  title={'Twitter'}
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter-square" />
                </a>
                <a
                  href="https://www.pinterest.com/easybusydo/"
                  target={'_blank'}
                  title={'Pinterest'}
                  rel="noreferrer"
                >
                  <i className="fab fa-pinterest-square" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCaABo6Brj3RjqDm82x3GPiw"
                  target={'_blank'}
                  title={'Youtube'}
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube-square" />
                </a>
                <a
                  href="https://www.instagram.com/easybusydo/"
                  target={'_blank'}
                  title={'Instagram'}
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram-square" />
                </a>
                <a
                  href="https://www.facebook.com/easybusydo/"
                  target={'_blank'}
                  title={'Facebook'}
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-square" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={'copyright'}>
          <p>
            Copyright {new Date().getFullYear()}, {` `} NEW_OWNER. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
