import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../../static/assets/logo-512.png';

const isActive = ({ isCurrent, href, location }) => {
  const className = isCurrent ? 'active' : 'inactive';
  return { className };
};

const Header = ({ siteTitle }) => (
  <header>
    <div className={'container'}>
      <div className={'top-menu'}>
        <div className={'logo'}>
          <Link to="/" title={'EasyBusy'}>
            <img alt={'EasyBusy Logo'} src={logo} />
          </Link>
        </div>

        <div className={'buttons-list'}>
          <div className={'button'}>
            <Link getProps={isActive} to="/versions/mobile">
              <i className="fas fa-mobile-alt" />
              Mobile app
            </Link>
          </div>

          <div className={'button'}>
            <Link getProps={isActive} to="/versions/desktop">
              <i className="fas fa-desktop" />
              Desktop app
            </Link>
          </div>

          <div className={'button'}>
            <Link getProps={isActive} to="/versions/web">
              <i className="fab fa-safari" />
              Web version
            </Link>
          </div>
        </div>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
